/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      //toggle search
      $( ".js--libby-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).addClass( "libby-search-overlay-wrapper--is-open" );
      });

      $( ".js-libby-close-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).removeClass( "libby-search-overlay-wrapper--is-open" );
      });

      //Create Owl Carousel for Hero
      jQuery('.libby_hero_slider').owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        dots: true,
        dotsEach: true,
        autoPlay: true,
        responsive: false,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true,
        nav: true
      });

      //Create Owl Carousel for Small Slider Hero
      jQuery('.libby_hero_sm_slider').owlCarousel({
        items: 1,
        loop: true,
        lazyLoad: true,
        dots: false,
        autoPlay: true,
        responsive: false,
        navigation: true,
        autoPlayTimeout: 1000,
        autoplayHoverPause: true
      });


      //News Slider
      $(document).ready(function() {
 
  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
 
  sync1.owlCarousel({
    singleItem : true,
    slideSpeed : 1000,
    navigation: true,
    pagination:false,
    afterAction : syncPosition,
    responsiveRefreshRate : 200,
  });
 
  sync2.owlCarousel({
    items : 15,
    itemsDesktop      : [1199,10],
    itemsDesktopSmall     : [979,10],
    itemsTablet       : [768,8],
    itemsMobile       : [479,4],
    pagination:false,
    responsiveRefreshRate : 100,
    afterInit : function(el){
      el.find(".owl-item").eq(0).addClass("owl-item--synced");
    }
  });
 
  function syncPosition(el){
    var current = this.currentItem;
    $("#sync2")
      .find(".owl-item")
      .removeClass("owl-item--synced")
      .eq(current)
      .addClass("owl-item--synced")
    if($("#sync2").data("owlCarousel") !== undefined){
      center(current)
    }
  }
 
  $("#sync2").on("click", ".owl-item", function(e){
    e.preventDefault();
    var number = $(this).data("owlItem");
    sync1.trigger("owl.goTo",number);
  });
 
  function center(number){
    var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
    var num = number;
    var found = false;
    for(var i in sync2visible){
      if(num === sync2visible[i]){
        var found = true;
      }
    }
 
    if(found===false){
      if(num>sync2visible[sync2visible.length-1]){
        sync2.trigger("owl.goTo", num - sync2visible.length+2)
      }else{
        if(num - 1 === -1){
          num = 0;
        }
        sync2.trigger("owl.goTo", num);
      }
    } else if(num === sync2visible[sync2visible.length-1]){
      sync2.trigger("owl.goTo", sync2visible[1])
    } else if(num === sync2visible[0]){
      sync2.trigger("owl.goTo", num-1)
    }
    
  }
 
});


      //toggle mobile dropdowns
      if (window.matchMedia('(max-width: 768px)').matches)
      {
        $( '.libby--main-menu .navbar-nav .menu-item-has-children>a' ).click(function() {
          event.preventDefault();
          $( this ).toggleClass( 'sub-menu--is-open' );
        });
      }
      
      //header search
      (function($) {
        var catalogSearchURL = $('.header--searchform').attr('action'), input;

        $('.header--searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_site' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
          }
        });
        
        $(".button_catalog").click(".button_catalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-s').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_site").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('input.libby-s').attr('placeholder', 'Search the website');
        });

      })(jQuery);

      //hero search
      (function($) {
        var catalogSearchURL = $('.hero--searchform').attr('action'), input;

        $('.hero--searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="libby_search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_hsite' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
            $(this).closest("form").attr('target', '_blank');
          }
        });
        
        $(".button_hcatalog").click(".button_hcatalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-search').attr('placeholder', 'Search the Catalog');
        });
        
        $(".button_hsite").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('input.libby-search').attr('placeholder', 'Search the Website');
        });

      })(jQuery);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //Toggling of Main Menu
  $(function(){
      $('#libby--nav-trigger #menu-main-menu>li>ul>li').on('keyup', function(e){
          if (e.keyCode === 39) {
              $(this).children('.dropdown-menu').toggle();
          }
      });
      $('#libby--nav-trigger #menu-main-menu>li').on('keyup', function(e){
          if (e.keyCode === 40) {
              $('#libby--nav-trigger #menu-main-menu>li>ul>li .dropdown-menu').css('display', 'none');
          }
      });

      $('#libby--nav-trigger #menu-main-menu>li>ul>li>a').on('click', function(){ 
          if($(window).width() <= 767){
              $(this).parentsUntil('#menu-main-menu').addClass('cl-show-menu');
              $(this).siblings().toggleClass('cl-showMenu');
          }     
      });
      $('#libby--nav-trigger #menu-main-menu>li>a').on('click', function(){
          if($(window).width() <= 767){
              $(this).siblings().toggle();
              $(this).siblings().toggleClass('cl-showMenu');
          }  
      });
  });


})(jQuery); // Fully reference jQuery after this point.
